import React from "react";
import { omit } from "lodash";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import OrderView from "../components/order-view";
import * as s from "../store/selectors";

import {
  removeItem,
  removeAllItems,
  saveOrderIfNeeded,
  startOrderItemEdit,
  setCouponsChecked,
  updateItemQuantity,
  resetEditItemMode,
  resetReorder,
  setChargeCardDeselected,
  setTableCode,
  setPickupTime,
  resetTableCode,
  cancelOrderItemEdit,
  setFutureServingTime,
  addPromoCode,
  removePromoCode,
} from "../store/order/actions";
import { resetGiftRedeem, selectDeal } from "../store/giftRedeem/actions";
import {
  loadBranchAvailabiltyIfNeeded,
  loadDeliveryAreas,
} from "../store/locations/actions";
import {
  openCheckoutView,
  closeCheckoutView,
  openAuthView,
} from "../store/app/actions";
import {
  loadLoyaltyProfile,
  dismissTradePointsNotice,
} from "../store/user/actions";

const OrderPage = (props) => <OrderView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    header,
    order,
    menuItemsConfiguration,
    app,
    giftRedeem,
  } = state;
  return {
    appStyles: s.getStyles(state, props),
    selectedServingOption: s.getOrderSelectedServingOption(state, props),
    servingOptionDetails: s.getServingOptionDetails(state, props),
    menuData: s.getAvailableMenuData(state, props),
    pickupTimes: s.getBranchPickupTimes(state, props),
    priceDetails: s.getPriceDetailsForCheckout(state, props),
    isSavingOrder: s.isSavingOrder(state, props),
    orderValidationErrors: s.getOrderValidationErrors(state, props),
    checkedoutOrderItems: s.getOrderItemsForCheckout(state, props),
    upsales: s.getUpsales(state, props),
    hasCouponsForCheckout: s.hasCouponsForCheckout(state, props),
    locations: omit(
      s.getAvailableLocations(state, props),
      "branchesAvailability",
    ),
    currentBranch: s.getOrderBranch(state, props),
    deals: s.getDeals(state, props),
    discountItems: s.getOrderDiscountItemsForCheckout(state, props),
    // itemImages: s.getItemImages(state, props),
    branchesAvailability: s.getBranchesAvailability(state, props),
    hasChargeCard: s.hasChargeCard(state, props),
    canUserLoadChargeCard: s.canUserLoadChargeCard(state, props),
    hasChargeCardDiscount: s.hasChargeCardDiscount(state, props),
    rechargeCardBenefitPrices: s.getRechargeCardBenefitPrices(state, props),
    userHasEnoughLoadedOnCardForOrder: s.userHasEnoughLoadedOnCardForOrder(
      state,
      props,
    ),
    predictedOrderPrice: s.predictedOrderPrice(state, props),
    order,
    giftRedeem,
    menuItemsConfiguration,
    user,
    header,
    app,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { servingOptions, menuData },
  } = props;

  return {
    updateItemQuantity: ({ itemIndex, count }) =>
      dispatch(updateItemQuantity({ itemIndex, count })),
    loadBranchAvailabiltyIfNeeded: () =>
      dispatch(loadBranchAvailabiltyIfNeeded()),
    loadDeliveryAreas: (params) => dispatch(loadDeliveryAreas(params)),
    loadLoyaltyProfile: (params) => dispatch(loadLoyaltyProfile(params)),
    removeItem: (index) => dispatch(removeItem(index)),
    removeAllItems: (params) => dispatch(removeAllItems(params)),
    startOrderItemEdit: (itemIndex, itemId, menuData) =>
      dispatch(startOrderItemEdit(itemIndex, itemId, menuData)),
    saveOrderIfNeeded: (options) =>
      dispatch(saveOrderIfNeeded(servingOptions, options)),
    setPickupTime: (params) => dispatch(setPickupTime(params)),
    setTableCode: (params) => dispatch(setTableCode(params)),
    resetTableCode: (params) => dispatch(resetTableCode(params)),
    cancelOrderItemEdit: (params) => dispatch(cancelOrderItemEdit(params)),
    setCouponsChecked: (params) => dispatch(setCouponsChecked(params)),
    setChargeCardDeselected: (isChargeCardDeselected) =>
      dispatch(setChargeCardDeselected(isChargeCardDeselected)),
    resetEditItemMode: () => dispatch(resetEditItemMode()),
    resetReorder: () => dispatch(resetReorder()),
    openAuthView: (loginType) => dispatch(openAuthView(loginType)),
    openCheckoutView: (params) => dispatch(openCheckoutView(params)),
    closeCheckoutView: (params) => dispatch(closeCheckoutView(params)),
    resetGiftRedeem: (params) => dispatch(resetGiftRedeem(params)),
    selectDeal: (deal) => dispatch(selectDeal(deal, menuData)),
    dismissTradePointsNotice: (params) =>
      dispatch(dismissTradePointsNotice(params)),
    setFutureServingTime: (params) => dispatch(setFutureServingTime(params)),
    addPromoCode: (params) => dispatch(addPromoCode(params)),
    removePromoCode: (params) => dispatch(removePromoCode(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(OrderPage));
